export const DEFAULT_AUTH_FAILURE = {};
export const DEFAULT_BASE_PATH = "";
export const DEFAULT_BASE_URL = "";
export const DEFAULT_USING_PROCORE_HOST_APP = false;

let authResult: object = DEFAULT_AUTH_FAILURE;
let basePath: string = DEFAULT_BASE_PATH;
let baseUrl: string = DEFAULT_BASE_URL;
let usingProcoreHostApp: boolean = DEFAULT_USING_PROCORE_HOST_APP;

export type AuthResult = {
   error?: string;
   status?: string;
};

export interface RequestContext {
   authResult: AuthResult;
   basePath: string;
   baseUrl: string;
   usingProcoreHostApp: boolean;
}

export const requestContext: RequestContext = {
   get authResult(): AuthResult {
      return authResult;
   },

   set authResult(val: AuthResult) {
      authResult = val;
   },

   get basePath(): string {
      return basePath;
   },

   set basePath(val: string) {
      basePath = val;
   },

   get baseUrl(): string {
      return baseUrl;
   },

   set baseUrl(val: string) {
      baseUrl = val;
   },

   get usingProcoreHostApp(): boolean {
      return usingProcoreHostApp;
   },

   set usingProcoreHostApp(val: boolean) {
      usingProcoreHostApp = val;
   },
};
