import insist from "@/vendor/insist";
import ko from "knockout";

export type ProcoreAccessTokenFragment = {
   access_token: string;
   expires_in: string;
   state: string;
   token_type: string;
};

function serializeParams(...params: [Record<string, unknown>, ...unknown[]]): string {
   // leaving so as not to alter existing behavior.
   insist.args(params, Object);

   let breakoutQueryParams = {};
   Object.entries(params[0]).forEach(([key, val]) => {
      breakoutQueryParams = {
         ...breakoutQueryParams,
         ...getQuery(key, val),
      };
   });

   return Object.entries(breakoutQueryParams)
      .map(([k, v]) => {
         return [k, v].join("=");
      })
      .join("&");
}

function getQuery(queryKey: string, queryValueInput: unknown): Record<string, string> {
   const unwrappedQueryValue = ko.toJS(queryValueInput);
   const queryValue =
      typeof unwrappedQueryValue === "string"
         ? encodeURIComponent(unwrappedQueryValue)
         : unwrappedQueryValue;

   if (queryValue === undefined || queryValue === null) {
      return {};
   }

   if (typeof queryValue != "object") {
      return { [queryKey]: `${queryValue}` };
   }

   const objectEntries = Object.entries(queryValue);
   // Breakout nested properties into their own query params and push up to the parent object.
   let result = {};
   for (const [k, v] of objectEntries) {
      const newKeyName = `${queryKey}[${k}]`;
      const nestedData = getQuery(newKeyName, v);
      result = {
         ...result,
         ...nestedData,
      };
   }
   return result;
}

function getURLFragment(): string {
   return window.location.hash;
}

export function getProcoreCompanyIdFromURL(
   url: string = window.location.pathname,
): string | undefined {
   // @ts-ignore
   return url.match(/companies\/(?<pcCompanyId>.*)\/tools/)?.groups?.pcCompanyId;
}

export function parseFragment(fragment: string): ProcoreAccessTokenFragment {
   return Object.fromEntries(
      fragment
         .substring(1)
         .split("&")
         .map((p) => p.split("=")),
   );
}

export const Url = {
   serializeParams,
   getProcoreCompanyIdFromURL,
   getURLFragment,
   parseFragment,
};
